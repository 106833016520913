import React, { Component } from 'react';
import axios from 'axios'


const UPLOAD_URL = "http://localhost:3000/image-upload"

class FileUpload extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fileSelected: false,
            fileToUpload: ""
        }

        //Give access to this for state
        this.uploadFile = this.uploadFile.bind(this)
    }

    uploadFile() {

        // Instantiate a FormData() object
        const imgBody = new FormData();
        imgBody.append('image', this.state.fileToUpload)
        //Check if a file exists to upload
        if (this.state.fileSelected) {
            //Proceed with upload
            axios.post(`${UPLOAD_URL}`, imgBody
            , {
                headers: {
                    // 'Content-Type' : 'multipart/form-data',
                }
            }).then(response=>{
                console.log(response)
            }).catch(error=>{
                console.log(error)
            })

        }else {
            alert("Please select a file to upload")
        }
    }

    onFileChange = e => {
        //A file was selected
        console.log(e.target.files[0])
        if (e.target.files[0]) {
            this.setState({ fileSelected: true, fileToUpload: e.target.files[0]})
            //We can handle the upload here or on hitting the upload button
        }else {
            console.log("No file selected")
            this.setState({ fileSelected: false})
        }
    }

    render() { 
        return (
            <div style={{padding: '20px'}}>
                <input type="file" name="fileUploader" onChange={this.onFileChange}/>
                <button style={styles.uploadButton} onClick={this.uploadFile}>Upload!</button>
            </div>
        );
    }
}

export default FileUpload;

const styles = {
    uploadButton: {
        display:'block',
        width: '200px',
        margin:'10px 0',
        border: 'none',
        backgroundColor: '#4bc9fb',
        padding: '10px',
        color: 'white',
        borderRadius: '25px',
        boxShadow: '0 4px 9px #00adec7a'
    },
}